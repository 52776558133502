import { SToast } from '@shein-aidc/sui-toast/mobile'
import schttp from 'public/src/services/schttp'

export const bffHttp = async (data, loadingConfig = {}) => {
  let result = null
  try {
    const res = await schttp({
      ...data,
      url: data.url,
      useBffApi: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      schttp: {
        loadingConfig
      }
    })
    const { info = {}, code } = res || {}
    if (typeof code === 'undefined' || code == 1002) {
      result = res // 没有code直接返回结果
    } else {
      result = code == 0 ? info : null
    }
  } catch(e) {
    const { language } = gbCommonInfo
    SToast(language.SHEIN_KEY_PWA_21283)
    console.warn(e, '接口错误', data)
  }
  return result
}
