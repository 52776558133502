<template>
  <div 
    ref="linePicContent"
    class="line-pic-content"
  >
    <div :class="[{ 'line-pic-content__content-opacity': isSoldOut }]">
      <section class="line-pic-content__content-title">
        <span
          class="line-pic-content__content-title_text"
          :class="{
            'line-pic-content__content-title_text-long': itemCount,
          }"
          tabindex="0"
          role="text"
          :aria-label="item.goods_name"
        >
          {{ item.goods_name }}
        </span>
        <div
          v-if="itemCount"
          class="line-pic-content__content-title_collect"
        >
          <i class="suiiconfont sui_icon_save_completed_20px"></i>
          {{ itemCount }}
        </div>
      </section>

      <PriceItem
        :exclusive-promotion-price="item.exclusivePromotionPrice"
        :constant-data="constantData"
        :retail-price="item.retailPrice"
        :sale-price="item.salePrice"
        :pretreat-info="pretreatInfo"
        :promotion-module="item.promotionModule"
        :shein-club-promotion-info="item.sheinClubPromotionInfo"
        :config="config"
      />

      <ProductItemRankingList 
        v-if="config.showRankingList && rankingList"
        :ranking-list="rankingList"
      /> 

      <!-- 比收藏时降价多少\活动、卖点标签 -->
      <ProductItemBottomLabels
        show-one-line
        :cur-data="item"
        :pretreat-info="pretreatInfo"
        :config="{
          ...config,
          showSaleAttr: false,
        }"
        :index="index"
        :goods-bottom-badge-style="goodsBottomBadgeStyle"
        :language="language"
        :is-sold-out="isSoldOut"
        :user-comment-keywords-label-lang="userCommentKeywordsLabelLang"
        :user-comment-keywords-label-info="userCommentKeywordsLabelInfo"
        :user-behavior-label-info="userBehaviorLabelInfo"
        :user-behavior-label-lang="userBehaviorLabelLang"
        :labels-show="labelsShow"
        :show-selling-points-on-label="config.showSellingPoint"
        :item-width="contentWidth"
        :has-normal-labels="hasNormalLabels"
        @exposeSellingPoint="exposeSellingPoint"
        @showCccTspBadge="$emit('showCccTspBadge', $event)"
        @showUserAct="$emit('showUserAct', $event)"
        @showQuickShip="$emit('showQuickShip', $event)"
      />
      <div
        v-if="saleAttrs"
        class="line-pic-content__sale-attr"
      >
        {{ saleAttrs }}
      </div>
    </div>
    <!-- 左下角找相识按钮 -->
    <s-button
      :type="['default', 'H48PX']"
      class="line-pic-content__content-similar j-item-find-similar"
      @click.prevent.stop="$emit('handleShowFindSimilar')"
    >
      {{ language.SHEIN_KEY_PWA_16789 }}
    </s-button>

    <!-- 右下角操作按钮 -->
    <RightBottomOperatorBtn
      v-show="!showSelect"
      :is-out-of-stock="isSoldOut"
      @handleQuickAdd="$emit('handleQuickAdd')"
      @onMove="$emit('handleBottomMove')"
    />
  </div>
</template>

<script>
import { SButton } from '@shein-aidc/sui-button/mobile'
import { defineComponent } from 'vue'
// component
import ProductItemBottomLabels from 'public/src/pages/components/product/item_v2/components/ProductItemBottomLabels.vue'
import RightBottomOperatorBtn from './rightBottomOperatorBtn.vue'
import PriceItem from './PriceItem.vue'
import ProductItemRankingList from 'public/src/pages/components/product/item_v2/components/ProductItemRankingList.vue'
import { getItemCollection } from 'public/src/pages/components/product/item_v3/components/ProductCardSlots/WishNum'

export default defineComponent({
  name: 'RightContentIndex',
  components: {
    RightBottomOperatorBtn,
    PriceItem,
    ProductItemBottomLabels,
    ProductItemRankingList,
    SButton,
  },
  emits: [
    'showCccTspBadge',
    'showUserAct',
    'showQuickShip',
    'handleShowFindSimilar',
    'handleQuickAdd',
    'handleBottomMove',
    'exposeSellingPoint',
  ],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({})
    },
    constantData: {
      type: Object,
      default: () => ({})
    },
    pretreatInfo: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    saleAttrs: { // 销售属性展示
      type: String,
      default: ''
    }, 
    showSelect: Boolean,
    showCollectNum: Boolean, // 是否展示收藏数
    index: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      contentWidth: 0
    }
  },
  computed: {
    // 收藏数
    itemCount () {
      return getItemCollection({ showCollectNum: this.showCollectNum, wishVal: this.item.count })
    },
    promotionInfo () {
      const promotionInfo = this.pretreatInfo?.mixPromotionInfo || []
      return promotionInfo[0] || {}
    },
    isSoldOut() {
      return this.pretreatInfo?.saleout?.show
    },
    rankingList() {
      return this.pretreatInfo?.mobileBroadwiseView?.rankingList
    },
    userBehaviorLabelInfo() {
      return this.pretreatInfo?.mobileBroadwiseView?.userBehaviorLabel
    },
    userBehaviorLabelLang() {
      return this.userBehaviorLabelInfo?.labelLang
    },
    userCommentKeywordsLabelInfo() {
      return this.pretreatInfo?.mobileBroadwiseView?.userCommentKeywordsLabel ?? {}
    },
    userCommentKeywordsLabelLang() {
      return this.userCommentKeywordsLabelInfo?.labelLang
    },
    goodsBottomBadgeStyle() {
      const style = {}
      const goodsBottom = this.pretreatInfo?.goodsBottom
      const fontColor = goodsBottom?.fontColor
      const backgroundColor = goodsBottom?.backgroundColor
      if(fontColor) style.color = fontColor
      if(backgroundColor) style.backgroundColor = backgroundColor

      return style
    },
    labelsShow() {
      const { localMall, language_mall_name } = this.pretreatInfo?.mallTagsInfo || {}
      const localMallName = localMall && language_mall_name
      // 底部标签
      return {
        localMallName: this.config.showLocalSeller && localMallName,
        quickShip: this.config.showQuickShip && this.item.quickship,
        priceCut: this.config.showPriceCut && this.pretreatInfo?.priceCut,
        promotionLabel: this.config.showPromotion && this.promotionInfo?.lable || '',
        sellingPoints: this.config.showSellingPoint && this.pretreatInfo?.sellingPoints,
        cccTspBadges: this.config.showBadge && !this.config.hideBottomCccTspBadge && this.pretreatInfo?.goodsBottom?.text,
      }
    },

    hasNormalLabels() {
      return Object.keys(this.labelsShow).some(key => this.labelsShow[key])
    }
  },
  mounted() {
    this.contentWidth = this.$refs?.linePicContent?.offsetWidth
  },
  methods: {
    exposeSellingPoint(sellingPointLabel) {
      this.$emit('exposeSellingPoint', sellingPointLabel)
    },
  },
})
</script>

<style lang="less">
.line-pic-content {
  position: relative;
  margin-left: 0.32rem;
  width: 100%;
  height: 100%;
  font-size: 0.27rem;
  max-width: 6.48rem;
  overflow: hidden;
  
  &__content-opacity {
    opacity: 0.4;
  }

  // 标题
  &__content-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #666;
    line-height: 0.39rem;
    &_text {
      width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;
    }
    // 收藏数
    &_collect {
      padding-left: 0.6rem;
      display: flex;
      justify-content: left;
      align-items: center;
      i {
        color: #959595;
        font-size: 0.4rem;
        padding-right: 0.05rem;
      }
    }
  }

  // 销售属性
  &__sale-attr {
    position: absolute;
    bottom: 0.74rem;
    left: 0;
    color: #666;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &__content-similar {
    position: absolute;
    bottom: 1px;
    left: 0;
  }

  // 边框圆角
  &__content-radius {
    border-radius: .3733rem;
  }

  .product-item__label-ctn {
    padding-top: 0;
  }
}
</style>
