import { nextTick } from 'vue'
// 同类推荐反馈弹窗用，FEEDBACK_REC_INFO_KEY 复用实时反馈的值
import { getFeedbackRecInSession, setFeedbackRecInSession } from './utils'
export default {
  methods: {
    getProductListItemInstance(index) {
      const productListItemRef = this.$refs?.[`WishProductItem_${index}`]?.$refs?.[`productItem_${index}`]
      return productListItemRef?.[0] || productListItemRef
    },
    // 通过商品的 goodsId 找到对应商品在 goods 列表中的 index
    // 注意: 入参的 goodsId 有可能是色块的 goodsId
    getCurItemIndex(goodsId) {
      return this.productList.findIndex(item => item.goods_id == goodsId || (item.relatedColor || []).find(color => color.goods_id == goodsId))
    },
    // 显示实时反馈弹窗
    async showSimilarRecPopup(isFromGoodsDetail = false) {
      const { goodsId, addBagStatus, addWishStatus, fromSimilarItemAdd } = getFeedbackRecInSession() || {}
      if(fromSimilarItemAdd || isFromGoodsDetail) {
        return
      }
      // 通过 goodsId 找到对应的 index
      const goodsIndex = this.getCurItemIndex(goodsId)
      if (goodsIndex < 0) return
      // 通过 goodsId 拿到对应的 productListItem 实例
      const listItemRef = this.getProductListItemInstance(goodsId)
      if (!listItemRef) return
      this.$refs[`WishProductItem_${goodsId}`].isShowRecommendSimilarAdd = true
      await nextTick()
      let recommendPopupRef = this.$refs?.[`WishProductItem_${goodsId}`]?.$refs?.[`recommendPopup_${goodsIndex}`]
      if (recommendPopupRef) {
        recommendPopupRef.setFeedbackSimilarItemsAdded({ goodsId, addBagStatus, addWishStatus })
        return
      }
      // 等待 recommendPopup 渲染出来
      await nextTick()
      setTimeout(()=> {
        recommendPopupRef = this.$refs?.[`WishProductItem_${goodsId}`]?.$refs?.[`recommendPopup_${goodsIndex}`]
        recommendPopupRef && recommendPopupRef.setFeedbackSimilarItemsAdded({ goodsId, addBagStatus, addWishStatus })
      }, 300)
    },
    // 隐藏实时反馈弹窗
    async hideSimilarRecPopup(isFromGoodsDetail = false) {
      if(isFromGoodsDetail && this.showWishlistSimilarType === 'close') {
        return
      }
      let { goodsId } = getFeedbackRecInSession() || {}
      // 通过 goodsId 找到对应的 index
      const goodsIndex = this.getCurItemIndex(goodsId)

      const recommendPopupRef = this.$refs?.[`WishProductItem_${goodsId}`]?.$refs?.[`recommendPopup_${goodsIndex}`]
      const recommendPopupInstance = recommendPopupRef?.[0] || recommendPopupRef
      if (goodsIndex >= 0) {
        // 再通过 index 拿到对应的 productListItem 实例
        const listItemRef = this.getProductListItemInstance(goodsId)
        if (listItemRef) {
          this.$refs[`WishProductItem_${goodsId}`].isShowRecommendSimilarAdd = false
          recommendPopupInstance?.showRecommendRecPopup({ show: !recommendPopupInstance?.show })
        }
      }
    },
    handleSimilarRecInfo ({ active, clickDetails, goodsId, addBagStatus, addWishStatus, isRecommend, fromSimilarItemAdd = false }) {
      if (!isRecommend) return
      if (!active) {
        // clickDetails 点击details跳去商详，会关闭弹窗。此种情况，不取弹窗里面的操作状态（加车和收藏）
        if (!clickDetails) {
          setFeedbackRecInSession({ goodsId, addBagStatus, addWishStatus, fromSimilarItemAdd })
          nextTick()
          this.showSimilarRecPopup()
        }
      } else {
        // 仅非售罄商品才触发（解决点击相似弹窗（售罄商品）关闭同类弹窗问题）
        !fromSimilarItemAdd && this.hideSimilarRecPopup()
      }
    }
  }
}
