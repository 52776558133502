<template>
  <div
    ref="rootCell"
    class="S-swipe-cell"
    @touchmove="onTouchmove"
    @touchstart="onTouchstart"
    @touchend="onTouchend"
    @touchcancel="onTouchend"
  >
    <div 
      class="S-swipe-cell__wrap" 
      :style="wrapperStyle"
    >
      <!-- 左边 -->
      <div
        v-if="renderSideContent('left')"
        ref="left"
        class="S-swipe-cell__left"
      >
        <slot name="left"></slot>
      </div>

      <!-- 内容 -->
      <slot></slot>

      <!-- 右边 -->
      <div
        v-if="renderSideContent('right')"
        ref="right"
        class="S-swipe-cell__right"
      >
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
// eslint-disable-next-line no-unused-vars
import { isDef, clamp, preventDefault, useEventListener } from './utils'
import useTouch from './useTouch'

export default defineComponent({
  emits: ['slideDone', 'close'],
  props: {
    leftWidth: {
      default: '',
      type: [String, Number],
    },
    rightWidth: {
      default: '',
      type: [String, Number],
    },
    // 禁止滑动
    disabled: {
      default: false,
      type: Boolean,
    },
    direction: {
      default: 'left', // 从左到右方向
      type: String,
    },
    stopPropagation: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      translateX: 0,
      maxRightMove: 0,
      maxLeftMove: 0,
      startOffset: 0,
      touchStage: false,
    }
  },
  computed: {
    wrapperStyle() {
      return {
        transitionDuration: `${!this.touchStage ? 300 : 0}ms`,
        transform: `translate3d(${this.translateX}px, 0px, 0px)`,
      }
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.touch = new useTouch()
      this.maxRightMove = this.getMaxMove('right')
      this.maxLeftMove = this.getMaxMove('left')
      this.onDirection()
      this.onAppTouch()
    },

    async onAppTouch() {
      await nextTick()
      useEventListener(this.$refs.rootCell, this.restoration, { type: 'touchstart' }, this)
    },

    // 位置复位
    restoration() {
      this.translateX = 0
    },

    renderSideContent(side) {
      return this.$slots[side]
    },

    // 处理翻转方向数据
    onDirection() {
      if (this.direction === 'left') return
      ;[this.maxLeftMove, this.maxRightMove] = [this.maxRightMove, this.maxLeftMove]
    },

    getWidthRef(el) {
      if (!el) return 0
      return el.offsetWidth
    },

    getMaxMove(refKey) {
      const curVal = refKey === 'right' ? this.rightWidth : this.leftWidth
      return isDef(curVal) ? +curVal : this.getWidthRef(this.$refs?.[refKey])
    },

    onTouchstart(e) {
      if (this.disabled) return
      this.startOffset = this.translateX
      this.touch.start(e)
    },

    onTouchmove(e) {
      if (this.disabled) return
      this.touch.move(e)
      if (!this.touch.isHorizontal()) return
      // preventDefault(e, this.stopPropagation)
      this.touchStage = true // 触摸阶段
      this.translateX = clamp(
        this.touch.deltaX + this.startOffset,
        -this.maxRightMove, // 从右到左。  值是应该是负数
        this.maxLeftMove
      )
    },

    onTouchend() {
      if (this.disabled) return
      if (this.touchStage) {
        this.touchStage = false
      }

      this.toggle(this.translateX < 0 ? 'right' : 'left')
    },

    toggle(slide) {
      const width = slide === 'right' ? this.maxRightMove : this.maxLeftMove
      const PROPORTION = 0.36 // 值越大，要滑动的距离越大显示完成
      if (width && Math.abs(this.translateX) > (width * PROPORTION)) {
        this.open(slide)
      } else {
        this.close(slide)
      }
     
    },
    open(slide) {
      this.translateX = slide === 'left' ? this.maxLeftMove : -this.maxRightMove
      if (this.startOffset !== this.translateX) {  // fix: 修复显示完全后，点击其他地方会二次触发
        this.$emit('slideDone', slide)
      }
    },
    close(slide) {
      this.translateX = 0
      this.$emit('close', slide)
    }
  },
})
</script>

<style lang='less'>
.S-swipe-cell {
  width: 100%;
  position: relative;
  user-select: none;
  overflow: hidden;
  touch-action: pan-y;
  &__wrap {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .slide-position() {
    position: absolute;
    top: 0;
    height: 100%;
  }

  &__left {
    .slide-position();
    left: 0;
    transform: translate3d(-100%, 0, 0);
  }

  &__right {
    .slide-position();
    right: 0;
    transform: translate3d(100%, 0, 0);
  }
}
</style>
