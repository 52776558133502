<template>
  <div class="line-right-btn">
    <section 
      class="line-right-bottom"
      :class="{'line-right-move' :isOutOfStock}"
    >
      <!-- 加车 -->
      <i 
        v-if="!isOutOfStock"
        class="suiiconfont sui_icon_addtobag_20px"
        tabindex="0"
        role="button"
        aria-label="ADD TO BAG"
        @click.prevent.stop="handleQuickAdd"
      ></i>
      
      <!-- 更多按钮 -->
      <i 
        class="suiiconfont sui_icon_more_20px line-right-bottom__more" 
        @click.prevent.stop="$emit('onMove')"
      ></i>
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  emits: [
    'onMove',
    'handleQuickAdd',
  ],
  props: {
    isOutOfStock: {
      type: Boolean,
      default: () => false
    },
  },
  methods: {
    handleQuickAdd() {
      this.$emit('handleQuickAdd')
    },
  },
})
</script>

<style lang='less'>
.line-right-btn {
  .line-right-bottom {
    position: absolute;
    right: 0;
    bottom: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #222;
    &__radius{
      border-radius: .3733rem;
    }

    &__add-cart {
      font-size: 0.53rem;
      padding-right: 6px;
    }

    &__more {
      padding-left: 6px;
      font-size: 16px;
    }
  }
  .line-right-move {
    bottom: -2px;
  }
}

</style>
